import Attachment from '@/components/fileUploading/attachment';
import GraphqlTable from '@/components/graphqlTable';
import { getRowProps } from '@/components/graphqlTable/getDeliveryStatusRowStyling';
import { QuickActionsType } from '@/components/graphqlTable/index';
import IncomingOutgoingItemsDialog from '@/components/incomingOutgoingItems';
import OverflowTypography from '@/components/overflowTypography';
import PageLink from '@/components/page/link';
import StyledImage from '@/components/styledImage';
import UserPopover from '@/components/userPopover';
import { InvoicesRead } from '@/data/commerce/invoice.graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { safeFormatInTimeZone } from '@/helpers/safeFormat';
import { PaymentTypeChip } from '@/hooks/paymentTypeChip';
import { findNextOccurrenceDate, getDueDateInfo } from '@/modals/repeatDateModal/utils';
import useOrdersExtraActions from '@/pages/dashboard/commerce/orders/actions/extraActions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModal } from '@/providers/modal';
import type { Order, QueryInvoicesReadArgs } from '@/types/schema';
import { AttachFile as AttachFileIcon, MoneyOff as MoneyOffIcon, Sell as SellIcon } from '@mui/icons-material';
import { Avatar, Chip, Stack, TableRowProps, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { endOfDay, format } from 'date-fns';
import { isEmpty, startCase, toLower } from 'lodash-es';
import type { Column } from 'react-table';
import CommerceStatusChip from '../chips/commerceStatusChip';
import MobileRowPanel from '../components/mobileRow';
import {
	CalculateBalance,
	CalculateClients,
	CalculateDate,
	CalculateNumber,
	CalculatePaymentType,
	CalculateTotal,
	CalculateUnique,
} from '../components/tableHelpers';
import CommerceTablePreviewDrawer from '../drawers/commerceTablePreviewDrawer';
import { getInvoiceBalance } from '../multiPayments/utils';
import InvoicesMultiActions from './actions/multiActions';
import useInvoicesQuickFilterActions from './actions/quickFilterActions';
import useInvoiceActions from './actions/tableActions';
import DeletedInvoicesModal from './deletedInvoices';
import RowPanel from './rowPanel';

export const columns: ( args: string ) => Array<Column<any> | null> = ( client ) => [ {
	accessor      : 'id',
	Header        : 'common:id',
	disableSortBy : true,
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 50 } },
}, {
	accessor   : ( row ) => row.metadata?.customNumber || row.number,
	Header     : 'commerce:invoice',
	filterByKey: 'number',
	props      : { style: { minWidth: 178 } },
	width      : 20,
	Cell       : ( { value, row } ) => {
		const { showModal } = useModal();
		
		return (
			<Stack
				sx={{ cursor: 'pointer' }}
				direction='row'
				spacing={1}
				alignItems='center'
				justifyContent='space-between'
				onClick={( e ) => {
					e.stopPropagation();
					showModal( CommerceTablePreviewDrawer, {
						variant: 'drawer',
						sx     : row.original.deletedAt ? { zIndex: 1400 } : undefined,
					}, {
						dataId: row.original.id,
						type  : 'INVOICE',
					} );
				}}>
				<Typography sx={{ textDecoration: 'underline' }}>{value}</Typography>
				{!isEmpty( row.original?.attachments ) && (
					<Tooltip title='Includes Attachments'>
						<AttachFileIcon sx={{ fontSize: 12, color: 'text.secondary' }}/>
					</Tooltip>
				)}
				{row.original?.externalId && client !== 'client' && (
					<Tooltip title='Available on Clover orders page'>
						<StyledImage
							alt='clover-pos-image'
							src='/images/clover-icon.png'
							width='10px'
							height='10px'
						/>
					</Tooltip>
				)}
				{row.original?.externalValue && client !== 'client' && (
					<Tooltip title='Available on Quickbooks'>
						<StyledImage
							alt='qb-icon'
							src='/images/qb-icon.png'
							width='12px'
							height='12px'
						/>
					</Tooltip>
				)}
				{row.original?.isRevenue === false && row.original?.externalId && (
					<Tooltip title={row.original?.type === 'ACCOUNT'
						? <Stack direction='row' spacing={1}>
							<Typography>Non-Revenue – The original orders have already been
								recorded as revenue in your reports. To prevent duplication, this invoice has been marked as
								non-revenue. To learn more about non-revenue house account statements, please visit your
							<PageLink
								href='/dashboard/settings/doc?tab=cloverTenders'
								target='_blank'
								sx={{ pl: .5 }}
								underline='hover'>
								document settings
							</PageLink>
							</Typography>
						</Stack> : row.original?.houseAccount ? <Stack direction='row' spacing={1}>
							<Typography>Non-Revenue – At the end of the month, your statement
								will be recorded as revenue. To prevent duplication, this order has been marked as non-revenue
								in your reports. To learn more about non-revenue house account orders, please visit your
							<PageLink
								href='/dashboard/settings/doc?tab=cloverTenders'
								target='_blank'
								sx={{ pl: .5 }}
								underline='hover'>
								document settings
							</PageLink>
							</Typography>
						</Stack> : 'Non-revenue'}>
						<MoneyOffIcon sx={{ fontSize: 15 }}/>
					</Tooltip>
				)}
			</Stack>
		);
	},
	Footer: ( { rows } ) => CalculateNumber( rows, 'Invoice' ),
}, client === 'houseAccount' ? {
	accessor   : ( row ) => row.externalId,
	Header     : 'Clover#',
	filterByKey: 'number',
	props      : { style: { minWidth: 140 } },
	width      : 15,
	Cell       : ( { value } ) => <OverflowTypography>{value}</OverflowTypography>,
	Footer     : ( { rows } ) => CalculateNumber( rows, 'Invoice' ),
} : null, {
	accessor       : ( row ) => row.client?.name || row.client?.contact,
	Header         : client ? 'Contact' : 'common:client',
	filterByKey    : 'client.contact',
	multipleFilters: [ 'name', 'contact' ],
	width          : 20,
	props          : { style: { minWidth: 178 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
	Footer: ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, client === 'houseAccount' ? {
	accessor       : 'employee' as any,
	Header         : 'Member',
	disableFilters : true,
	multipleFilters: [ 'name', 'email' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { row: { original } } ) => {
		if ( original.employee?.cardNumber ) {
			return (
				<Tooltip
					key={original.employee?.id}
					placement='top'
					title={`Card ending in ***${original.employee?.cardNumber?.slice( -4 )}`}>
					<OverflowTypography>
						{original.employee?.name || original.employee?.email || 'Card holder'}
					</OverflowTypography>
				</Tooltip>
			);
		} else return (
			<OverflowTypography>
				{original.client?.name || original.client?.contact || original.houseAccount?.name || original.houseAccount?.contact}
			</OverflowTypography>
		);
	},
	Footer: ( { rows } ) => CalculateClients( rows, [ 'employee.name', 'employee.email' ], 'Employee' ),
} : null, {
	accessor   : 'client.id' as any,
	Header     : 'common:client-id',
	filterByKey: 'client.id',
	width      : 20,
	props      : { style: { minWidth: 168 } },
	Cell       : ( { value } ) => <OverflowTypography>{value || '-'}</OverflowTypography>,
	Footer     : ( { rows } ) => CalculateClients( rows, 'Client', 'Client' ),
}, client === 'client' ? {
	accessor      : 'company' as any,
	Header        : 'common:company',
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => value?.name || value?.contact || '-',
	Footer        : ( { rows } ) => CalculateClients( rows, [
		'company.name',
		'company.contact' ], 'Company' ),
} : null, !client ? {
	accessor      : 'houseAccount' as any,
	Header        : 'common:house-account',
	disableFilters: true,
	width         : 20,
	props         : { style: { minWidth: 168 } },
	Cell          : ( { value } ) => <OverflowTypography>{value?.name || value?.contact || '-'}</OverflowTypography>,
	Footer        : ( { rows } ) => CalculateClients( rows, [
		'houseAccount.name',
		'houseAccount.contact' ], 'House Account' ),
} : null, !client ? {
	accessor   : 'houseAccount.id' as any,
	Header     : 'common:house-account-id',
	filterByKey: 'houseAccount.id',
	width      : 20,
	props      : { style: { minWidth: 168 } },
	Cell       : ( { value } ) => <OverflowTypography>{value || '-'}</OverflowTypography>,
	Footer     : ( { rows } ) => CalculateClients( rows, 'houseAccount.id', 'HouseAccount' ),
} : null, !client ? {
	accessor     : 'type',
	Header       : 'common:type',
	// disableFilters: true,
	filterByKey  : 'type',
	filterOptions: [ 'INVOICE', 'ACCOUNT', 'STATEMENT' ],
	Cell         : ( { value } ) => value ? startCase( toLower( value ) ) : ( '-' as any ),
	props        : { style: { minWidth: 100 } },
	width        : 12,
} : null, !client ? {
	accessor   : 'metadata.sentDates' as any,
	Header     : 'Sent Dates',
	sortType   : 'exist',
	filterByKey: 'metadata.sentDates',
	Cell       : ( { value } ) => value?.[ 0 ] ? (
		<OverflowTypography>
			{format( new Date( value[ 0 ] ), 'PPp' )}
		</OverflowTypography>
	) : <Typography>-</Typography>,
	props: { style: { minWidth: 135 } },
	width: 12,
} : null, {
	accessor       : 'status',
	Header         : 'common:status',
	filterByKey    : 'status',
	multipleFilters: [ 'SENT', 'VIEWED', 'PARTIALLY_PAID', 'ACCOUNT' ],
	filterOptions  : [
		...client ? [] : [ 'DRAFT' ],
		...[
			'ACCOUNT',
			'CANCELLED',
			'COLLECTIONS',
			'COMPLETED',
			'DONATED',
			'MERGED',
			'OPEN',
			'PAID',
			'PARTIALLY_PAID',
			'PAST_DUE',
			'REFUNDED',
			'SENT',
			'STANDING',
			'VIEWED',
			'WRITTEN_OFF',
			'VOIDED',
		],
	],
	width : 8,
	props : { style: { minWidth: 160 } },
	Cell  : ( data ) => <CommerceStatusChip cellData={data}/>,
	Footer: ( { rows } ) => CalculateUnique( rows, 'status' ),
}, {
	accessor     : 'payments',
	Header       : 'common:payment',
	sortType     : 'options',
	filterByKey  : 'payments.type',
	filterOptions: [ 'CARD', 'Credit Card', 'Invoiss', 'Bank Transfer', 'Debit Card', 'Cash', 'Check' ],
	width        : 10,
	props        : { style: { minWidth: 160 } },
	Cell         : ( { value } ) => <PaymentTypeChip payments={value} name='invoice'/>,
	Footer       : ( { rows } ) => CalculatePaymentType( rows ),
}, {
	accessor: 'dueDate',
	Header  : 'common:due',
	sortType: 'datetime',
	width   : 10,
	props   : { style: { minWidth: 170 } },
	Cell    : ( { cell } ) => {
		const order = cell.row.original;
		if ( order.standingData && order.standingActive ) {
			return (
				<Tooltip title='This date represents the next occurrence.'>
					<Typography>
						{safeFormatInTimeZone( findNextOccurrenceDate( order.standingData ), 'PPP' )}
					</Typography>
				</Tooltip>
			);
		} else if ( order && ( order.status !== 'ACCOUNT' || ( order.client?.terms || 0 ) > 0 ) ) {
			const { getDueDateText, getDueDateColor } = getDueDateInfo( order );
			
			return (
				<Tooltip
					key={order}
					placement='right'
					title={safeFormatInTimeZone( order.dueDate, 'PP' )}>
					<Typography color={getDueDateColor()}>{getDueDateText()}</Typography>
				</Tooltip>
			);
		} else return null;
	},
	Footer: ( { rows } ) => CalculateDate( rows, 'dueDate' ),
}, !client ? {
	accessor       : ( row ) => row.companyLocation?.name || row.companyLocation?.address?.line1,
	Header         : 'management:location',
	filterByKey    : 'companyLocation.name',
	multipleFilters: [ 'name', 'address.line1' ],
	width          : 20,
	props          : { style: { minWidth: 168 } },
	Cell           : ( { value } ) => value ? ( (
		<OverflowTypography>{value}</OverflowTypography>
	) as any ) : '-',
} : null, {
	accessor      : 'po',
	Header        : 'common:po',
	disableFilters: true,
	Cell          : ( { value } ) => value || '',
	props         : { style: { minWidth: 135 } },
	width         : 12,
}, {
	accessor: 'serviceDate',
	Header  : 'common:service-date',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 170 } },
	Cell    : ( { value } ) => value
		? ( format( value, 'PPp' ) as any )
		: '',
	Footer: ( { rows } ) => CalculateDate( rows, 'serviceDate' ),
}, client === 'deletedAt' ? {
	accessor: 'deletedAt',
	Header  : 'common:deletedAt',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'deletedAt' ),
} : null, !client ? {
	accessor   : 'staff' as any,
	Header     : 'common:placed-by',
	sortType   : 'select',
	filterByKey: 'staff.user.firstName',
	width      : 10,
	props      : { style: { minWidth: 130 } },
	Cell       : ( { value } ) => (
		<UserPopover user={value?.user}>
			<Chip
				label={value?.user?.firstName}
				size='small'
				avatar={(
					<Avatar
						alt={value?.user?.firstName}
						src={value?.user?.image}
						sx={{
							border     : 1.5,
							borderColor: 'colors.opposite',
						}}
					/>
				)}
			/>
		</UserPopover>
	),
} : null, {
	accessor      : 'metadata.signature' as any,
	Header        : 'common:signature',
	disableSortBy : true,
	disableFilters: true,
	props         : { style: { minWidth: 90 } },
	width         : 12,
	Cell          : ( { value } ) => value ? (
		<Attachment
			whiteBg
			removeDownload
			src={value}
			imageSX={{
				width    : 40,
				height   : 40,
				objectFit: 'cover',
				mb       : 0,
			}}
		/>
	) : null,
}, !client ? {
	accessor: 'createdAt',
	Header  : 'common:created-date',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 170 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'createdAt' ),
} : null, {
	accessor: 'updatedAt',
	Header  : 'common:updated',
	sortType: 'datetime',
	width   : 20,
	props   : { style: { minWidth: 168 } },
	Footer  : ( { rows } ) => CalculateDate( rows, 'updatedAt' ),
}, {
	accessor: 'taxName',
	Header  : 'Tax Name',
	width   : 20,
	props   : { style: { minWidth: 168 } },
}, {
	accessor: 'taxTotal',
	Header  : 'common:tax',
	sortType: 'number',
	width   : 20,
	props   : { style: { minWidth: 138 } },
	Cell    : ( { value } ) => currencyFormat( value ) as any,
	Footer  : ( { rows } ) => CalculateTotal( rows, 'taxTotal', true ),
}, {
	Header  : 'common:balance',
	sortType: 'number',
	width   : 20,
	props   : { style: { minWidth: 138 } },
	Cell    : ( { row } ) => {
		const balance = getInvoiceBalance( row.original );
		return currencyFormat( balance ) as any;
	},
	Footer: ( { rows } ) => CalculateBalance( rows, true ),
}, {
	accessor: 'grandTotal',
	Header  : 'common:total',
	sortType: 'number',
	width   : 10,
	props   : { style: { minWidth: 138 } },
	Cell    : ( { value, row } ) => {
		let tipsTotal = 0;
		if ( client === 'houseAccount' ) {
			tipsTotal = row.values.payments?.reduce( ( sum, payment ) => {
				if ( payment.tip > 0 ) {
					sum += payment.tip;
				}
				return sum;
			}, 0 );
		}
		return currencyFormat( Math.abs( +value + tipsTotal ) ) as any;
	},
	Footer: ( { rows } ) => CalculateTotal( rows, 'grandTotal', true ),
} ].filter( Boolean );

export default function InvoiceTable() {
	const { staff } = useUserInfo();
	const { showModal } = useModal();
	const theme = useTheme();
	const darkMode = theme.palette.mode === 'dark';
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isIpad = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'md' ) );
	const invoiceFilters = staff?.metadata?.invoices;
	const { variables, actions } = useOrdersExtraActions();
	
	return (
		<GraphqlTable<Order, QueryInvoicesReadArgs>
			showFooter
			searchable
			rowRoute={( { id } ) => `/dashboard/commerce/invoices/${id}`}
			queryKey='invoice'
			query={InvoicesRead}
			subscription={{ INVOICE: staff?.company.id as string }}
			columns={columns}
			hiddenTableColumns='invoicesHiddenColumns'
			tableSavedFilters={[
				'invoices',
				'Show All',
				'Not Completed',
				'Dues & Past Dues',
				'Stocks not updated',
				'Non revenue',
				'Available on Clover',
				'Not Available on Clover',
			]}
			tableActionMenu={[ 'Export', 'invoicesPDF', 'invoicesCSV' ]}
			variables={{
				options: {
					filter: {
						$and: invoiceFilters === 'Stocks not updated' ? [
							{ $or: [ { metadata: { stock: null } }, { metadata: { stock: false } } ] },
						] : undefined,
						dueDate: invoiceFilters === 'Dues & Past Dues'
							? { $lte: endOfDay( new Date() ) }
							: undefined,
						companyLocation: variables?.location?.id,
						isRevenue      : invoiceFilters === 'Non revenue' ? false : undefined,
						externalId     : invoiceFilters === 'Available on Clover' ? { $ne: null }
							: invoiceFilters === 'Not Available on Clover' ? { $eq: null } : undefined,
						status:
							invoiceFilters === 'Not Completed' || invoiceFilters === 'Dues & Past Dues'
								? { $ne: 'PAID' }
								: undefined,
					},
				},
			}}
			initialState={{
				hiddenColumns: staff?.metadata?.invoicesHiddenColumns
					? staff?.metadata?.invoicesHiddenColumns
					: [
						'id',
						'balance',
						'Clover#',
						'taxName',
						'taxTotal',
						'updatedAt',
						'clover',
						'client.id',
						'houseAccount.id',
						'houseAccount',
						'metadata.signature',
						'po',
						'management:location',
						'createdAt',
						'type',
						'metadata.sentDates',
					],
			}}
			expandedComponent={( row ) => <RowPanel row={row}/>}
			rowProps={( row ) => getRowProps( row, darkMode ) as TableRowProps}
			useActions={useInvoiceActions}
			useQuickFilters={useInvoicesQuickFilterActions as () => QuickActionsType[]}
			renderDeletedData={() => showModal( DeletedInvoicesModal, { maxWidth: 'xl' } )}
			mobileRenderRow={( invoice ) => <MobileRowPanel data={invoice as any}/>}
			renderMultiActions={( invoices, clearRows, selectedRows ) => (
				<InvoicesMultiActions
					invoices={invoices}
					clearRows={clearRows}
					selectedInvoices={selectedRows}
				/>
			)}
			headerExtraActions={{
				items: [ ...actions, !isMobile && {
					name   : 'Outgoing Items',
					details: 'See ordered items that need to be delivered',
					icon   : <SellIcon/>,
					onClick: () => {
						showModal( IncomingOutgoingItemsDialog, { variant: 'fullPageDialog' } );
					},
				} ],
				max: isIpad ? 2 : 4,
			}}
		/>
	
	);
}
